<template>
  <v-list-item :active="active" density="compact" slim>
    <template #prepend>
      <v-list-item-action start>
        <v-checkbox-btn v-model="selection" :value="item" @click.stop />
      </v-list-item-action>
    </template>

    <v-list-item-title>
      <v-icon
        v-if="hasToBeIndented"
        class="mr-1"
        icon="ph:arrow-elbow-down-right"
        :size="18"
      />

      <span
        class="text-sm"
        :class="{ 'font-semibold': !hasParent }"
        v-html="searchMatchHighlights['title']"
      />
    </v-list-item-title>

    <div :class="{ 'pl-6': hasToBeIndented }">
      <p class="text-subtle text-xs">
        <span v-html="searchMatchHighlights['teacher.full_name']" />
        <template v-if="item.proposal">
          | From
          <span
            v-html="searchMatchHighlights['proposal.customer_company_name']"
          />
          -
          {{ item.proposal.title }}
        </template>
      </p>

      <p
        v-if="searchMatchHighlights.outcomes"
        class="text-subtle mt-1 text-xs italic"
        v-html="searchMatchHighlights.outcomes"
      />
    </div>

    <template #append>
      <div class="flex gap-1">
        <v-chip
          v-if="statusChip"
          :color="statusChip.color"
          :prepend-icon="statusChip.icon"
          size="x-small"
          :variant="statusChip.variant"
        >
          {{ statusChip.label }}
        </v-chip>

        <v-chip label size="x-small">
          {{ item.content_type_label }} - {{ item.format_label }}
        </v-chip>
      </div>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import type { ProposalSearchableLineItemListView } from "~/business-areas/proposal/composables/proposal-searchable-line-item-list.hook";
import {
  type TextSearchMatch,
  useTextSearchMatchesHighlightMapping,
} from "~/shared/text/composables/text-search-match.hook";
import type { ProposalSearchableLineItemListApiView } from "~~/model/proposal.model";

const selection = defineModel<ProposalSearchableLineItemListView[]>({
  required: true,
});

const properties = withDefaults(
  defineProps<{
    item: ProposalSearchableLineItemListView;
    matches?: readonly TextSearchMatch[];
    active?: boolean;
    previous?: Pick<
      ProposalSearchableLineItemListApiView,
      "course_id" | "parent_course_id"
    >;
  }>(),
  {
    matches: () => [],
    previous: undefined,
  },
);

const searchMatchHighlights = useTextSearchMatchesHighlightMapping(
  computed(() => properties.matches),
  [
    ["title", properties.item.title],
    ["teacher.full_name", properties.item.teacher?.full_name],
    "outcomes",
    [
      "proposal.customer_company_name",
      properties.item.proposal?.customer_company_name,
    ],
  ],
);

const hasParent = computed(() => {
  return properties.item.parent_course_id !== null;
});

const hasToBeIndented = computed(() => {
  if (!properties.previous || properties.item.parent_course_id === null)
    return false;

  return (
    properties.item.parent_course_id === properties.previous.parent_course_id ||
    properties.item.parent_course_id === properties.previous.course_id
  );
});

const statusChip = computed<
  | {
      label: string;
      icon: string;
      variant: "flat" | "outlined";
      color?: string;
    }
  | undefined
>(() => {
  const variant = properties.item.parent_course_id ? "outlined" : "flat";

  if (properties.item.searchable_line_item_type === "PROPOSAL_LINE_ITEM") {
    return {
      label: "Prop. item",
      icon: "ph:pencil",
      color: "warning",
      variant,
    };
  }
});
</script>
