import MarkdownIt from "markdown-it";
import type { MaybeRef } from "vue";

const md = new MarkdownIt();

export function useTextMarkdown(input: MaybeRef<string | undefined | null>) {
  return computed(() => {
    const value = unref(input);

    if (!value) return;

    return md.render(value);
  });
}
