<template>
  <app-modal
    :disabled="selection.length === 0"
    title="Create a new line item from existing content"
    :width="1920"
    @submit="onSubmit"
  >
    <div class="flex flex-wrap gap-2">
      <app-form-input-text
        v-model="query"
        autofocus
        class="w-full"
        clearable
        hide-details
        icon="ph:magnifying-glass"
        placeholder="Search by title, trainer, content..."
      />

      <tag-autocomplete v-model="filters.tags" class="max-w-[300px]" multiple />
    </div>

    <div class="h-[calc(100vh-350px)]">
      <div
        v-show="status === 'pending' && data.length === 0"
        class="grid h-full place-content-center"
      >
        <v-progress-circular color="primary" indeterminate :size="30" />
      </div>

      <div v-show="isEmpty" class="grid h-full place-content-center gap-4">
        <p class="text-center text-lg">
          Sorry, no courses or items match with “{{ query }}”.<br />
          You can create an new item from scratch
        </p>

        <v-btn
          class="mx-auto"
          color="primary"
          prepend-icon="ph:plus"
          size="large"
          @click="onAddFromScratchClick"
        >
          Add a new item from scratch
        </v-btn>
      </div>

      <div v-show="data.length > 0" class="grid h-full grid-cols-[50%_1fr]">
        <v-list
          class="overflow-auto !border-r"
          color="primary"
          density="compact"
          select-strategy="leaf"
        >
          <proposal-searchable-line-item-list-item
            v-for="(result, index) in data"
            :key="result.item.id"
            v-model="selection"
            :active="active?.refIndex === result.refIndex"
            class="visibility-auto"
            :item="result.item"
            :matches="result.matches"
            :previous="
              data[index - 1]?.item
                ? pick(data[index - 1]!.item, 'course_id', 'parent_course_id')
                : undefined
            "
            @click="onItemClick(result)"
          />
        </v-list>

        <div class="overflow-auto px-8">
          <proposal-searchable-line-item-overview
            v-if="active"
            :item="active.item"
          />
        </div>
      </div>
    </div>

    <template #secondaryActions>
      <ul class="flex flex-wrap gap-1">
        <li v-for="(item, index) in selection" :key="index">
          <v-chip label size="small">
            {{ item.title }}
          </v-chip>
        </li>
      </ul>
    </template>
  </app-modal>
</template>

<script lang="ts" setup>
import { omit, pick } from "lodash";

import ProposalSearchableLineItemListItem from "~/business-areas/proposal/components/proposal-searchable-line-item-list-item.vue";
import { EMPTY_PROPOSAL_LINE_ITEM } from "~/business-areas/proposal/composables/proposal-editable-view.hook";
import { useProposalSearchableLineItemList } from "~/business-areas/proposal/composables/proposal-searchable-line-item-list.hook";
import type { ProposalUpdateRequest } from "~~/model/proposal.model";

const properties = defineProps<{
  proposal: ProposalUpdateRequest;
}>();

const { data, query, isEmpty, filters, status } =
  useProposalSearchableLineItemList({
    exclude: properties.proposal.line_items.map((item) => item.id),
  });

const selection = ref<(typeof data.value)[number]["item"][]>([]);
const active = ref<(typeof data.value)[number] | undefined>();

function onSubmit() {
  return useModal("proposalSearchableLineItemModal").close({
    confirmed: true,
    payload: selection.value.map((item) => {
      return {
        ...omit(
          item,
          "id",
          "teacher",
          "proposal",
          "searchable_line_item_type",
          "tags",
          "course_id",
        ),
        parent_course_id: item.parent_course_id ?? item.course_id,
        tags: item.tags.map((tag) => tag.id),
      };
    }),
  });
}

function onItemClick(item: typeof active.value) {
  active.value = item;
}

function onAddFromScratchClick() {
  return useModal("proposalSearchableLineItemModal").close({
    confirmed: true,
    payload: [{ ...EMPTY_PROPOSAL_LINE_ITEM }],
  });
}
</script>
