// Type copied from fuse.js lib. Used under the hood by @vueuse/integrations useFuse hook
import type { MaybeRef } from "vue";

export type TextSearchMatch = {
  indices: ReadonlyArray<[number, number]>;
  key?: string;
  refIndex?: number;
  value?: string;
};

export function useTextSearchMatchesHighlightMapping<const K extends string>(
  matches: MaybeRef<readonly TextSearchMatch[]>,
  mapping: Array<K | [key: K, defaultValue: string | undefined | null]>,
) {
  return computed<Record<K, string | undefined>>(() => {
    const _matches = unref(matches);

    return mapping.reduce(
      (registry, entry) => {
        const [key, defaultValue] = Array.isArray(entry) ? entry : [entry];

        const match = _matches.find((_match) => _match.key === key);

        registry[key] = match
          ? match.value?.split("").reduce((html, character, index) => {
              const indexIsMatchStart = match.indices.some(
                ([start]) => index === start,
              );
              const indexIsMatchEnd = match.indices.some(
                ([, end]) => index === end,
              );

              if (indexIsMatchStart)
                html = `${html}<mark class="bg-warning/15 rounded ring-warning/30 ring-1">${character}`;
              if (indexIsMatchEnd) html = `${html}${character}</mark>`;
              if (!indexIsMatchEnd && !indexIsMatchStart)
                html = `${html}${character}`;

              return html;
            }, "")
          : (defaultValue ?? undefined);

        return registry;
      },
      {} as Record<K, string | undefined>,
    );
  });
}
