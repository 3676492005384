<template>
  <section>
    <template v-if="item.teacher">
      <header class="mb-4 flex gap-4">
        <app-image
          :alt="item.teacher.full_name!"
          class="h-20 w-20 overflow-hidden rounded-lg object-cover"
          :src="item.teacher.avatar_url"
        />

        <div class="flex-auto">
          <p class="text-lg font-bold">
            {{ item.teacher.full_name }}
          </p>
          <p>{{ item.teacher.job }}</p>

          <ul class="mt-2 flex flex-wrap gap-x-2 gap-y-1">
            <li v-for="company in item.teacher.companies" :key="company.id">
              <app-image
                :alt="company.name"
                class="h-20"
                :height="20"
                :src="company.logo_url"
              />
            </li>
          </ul>
        </div>
      </header>

      <div class="prose text-sm" v-html="descriptionHtml" />

      <div class="mt-4 grid gap-4">
        <section v-for="section in sections" :key="section.title">
          <h3 class="mb-2 text-lg font-semibold">
            {{ section.title }}
          </h3>

          <ul class="prose list-decimal text-sm">
            <li
              v-for="(item, index) in section.items"
              :key="index"
              v-html="useTextMarkdown(item).value"
            />
          </ul>
        </section>

        <section>
          <h3 class="mb-2 text-lg font-semibold">Programme</h3>

          <ul class="prose list-decimal text-sm">
            <li
              v-for="(chapter, index) in item.program"
              :key="index"
              class="font-semibold"
            >
              {{ chapter.title }}
              <ul class="font-normal">
                <li
                  v-for="(section, sectionIndex) in chapter.sections ?? []"
                  :key="sectionIndex"
                >
                  {{ section.title }}
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </template>
  </section>
</template>

<script lang="ts" setup>
import { useTextMarkdown } from "~/shared/text/composables/text-markdown.hook";
import type { ProposalSearchableLineItemListApiView } from "~~/model/proposal.model";

const properties = defineProps<{
  item: ProposalSearchableLineItemListApiView;
}>();

const descriptionHtml = useTextMarkdown(
  computed(() => properties.item.teacher?.description),
);

const sections = computed<{ title: string; items: string[] }[]>(() => {
  return [
    {
      title: "Learning goals",
      items: properties.item.outcomes ?? [],
    },
    ...(properties.item.additional_contents ?? []).map((content) => {
      return {
        title: content.title ?? "",
        items: content.items ?? [],
      };
    }),
  ];
});
</script>
