import { useFuse } from "@vueuse/integrations/useFuse";

import type { ProposalSearchableLineItemListApiView } from "~~/model/proposal.model";

export type ProposalSearchableLineItemListView =
  ProposalSearchableLineItemListApiView;

export function useProposalSearchableLineItemList(options?: {
  exclude?: DatabaseTable<"proposal_line_items">["id"][];
}) {
  const filters = reactive({
    tags: [] as DatabaseTable<"tags">["id"][],
    exclude: options?.exclude ?? [],
  });

  const { data, status } = useLazyFetch(
    "/api/proposals/searchable-line-items",
    {
      query: computed(() => ({
        exclude: options?.exclude,
        tags: filters.tags,
      })),
      default: () => [],
      transform(data) {
        if (!data) return [];

        return data
          .reduce(
            (groups, result) => {
              if (
                result.searchable_line_item_type === "COURSE" &&
                result.parent_course_id === null
              ) {
                const matchingGroup = groups.find(
                  (group) => group.parent_course_id === result.course_id,
                );

                if (matchingGroup) {
                  matchingGroup.items.unshift(result);
                } else {
                  groups.push({
                    parent_course_id: result.course_id,
                    items: [result],
                  });
                }

                return groups;
              }

              if (result.parent_course_id !== null) {
                const matchingGroup = groups.find(
                  (group) => group.parent_course_id === result.parent_course_id,
                );

                if (matchingGroup) {
                  matchingGroup.items.push(result);
                } else {
                  groups.push({
                    parent_course_id: result.parent_course_id ?? null,
                    items: [result],
                  });
                }

                return groups;
              }

              const matchingGroup = groups.find(
                (group) => group.parent_course_id === null,
              );

              if (matchingGroup) {
                matchingGroup.items.push(result);
              } else groups.push({ parent_course_id: null, items: [result] });

              return groups;
            },
            [] as {
              parent_course_id: DatabaseTable<"courses">["id"] | null;
              items: ProposalSearchableLineItemListApiView[];
            }[],
          )
          .flatMap((group) => group.items);
      },
    },
  );

  const query = ref("");

  const { results } = useFuse(query, data, {
    matchAllWhenSearchEmpty: true,
    fuseOptions: {
      keys: [
        "title",
        "outcomes",
        "teacher.full_name",
        "proposal.customer_company_name",
      ],
      threshold: 0.3,
      includeMatches: true,
      minMatchCharLength: 2,
      ignoreLocation: true,
    },
  });

  const isEmpty = computed(
    () => results.value.length === 0 && status.value === "success",
  );

  return {
    query,
    filters,
    data: results,
    status,
    isEmpty,
  };
}
